<template>
  <v-card class="border-secondary rounded-xl pa-sm-8" elevation="0" width="100%" max-width="500px">
    <v-card-title class="pa-sm-0 mb-4">
      <v-img :src="getAdNetworkImages.theme_white_logo" contain max-height="80" class="mx-auto" />
    </v-card-title>

    <v-card-text class="pb-sm-0">
      <v-checkbox
        v-model="notificationsAboutCampaignActions"
        class="pa-0 ma-0 mb-3"
        label="Notifications about actions on campaigns"
        hide-details
        @change="unsubscribe()"
      />
      <v-checkbox
        v-model="notificationsAboutAdActions"
        class="pa-0 ma-0"
        label="Notifications about actions on ads"
        hide-details
        @change="unsubscribe()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import personalRepository from '@/services/repositories/personal-repository.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'UnsubscribePage',
    data() {
      return {
        notificationsAboutAdActions: false,
        notificationsAboutCampaignActions: false,
        email: null,
        signature: '',
        isLoading: false
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdNetworkImages'])
    },
    async created() {
      this.email = this.$route.params.email
      this.signature = this.$route.query.signature
      await this.getActualSubscribeData()
    },
    methods: {
      async getActualSubscribeData() {
        this.isLoading = true
        try {
          const response = await personalRepository.actualSubscribeData(this.email, this.signature)
          this.notificationsAboutAdActions = response.notificationsAboutAdActions
          this.notificationsAboutCampaignActions = response.notificationsAboutCampaignActions
        } catch {
          this.$showErrorNotification('Error')
        } finally {
          this.isLoading = false
        }
      },

      async unsubscribe() {
        this.isLoading = true
        try {
          await personalRepository.unsubscribe({
            email: this.email,
            notificationsAboutAdActions: this.notificationsAboutAdActions,
            notificationsAboutCampaignActions: this.notificationsAboutCampaignActions,
            signature: this.signature
          })
        } catch (error) {
          handleErrors(error)
          this.isLoading = false
        }
      }
    }
  }
</script>
